import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import PurpleBtn from "../Components/PurpleBtn";
import TransparentBtn from "../Components/TransParentBtn";

const DownloadPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isDownloaded, setIsDownloaded] = useState(false);

  const download = () => {
    if (isDownloaded === true) {
      window.open(process.env.REACT_APP_DRB, "_blank", "noreferrer")
    } else {
      saveAs(state.data, "stix-result");
      setIsDownloaded(true)
    }

  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="w-full h-full bg-[#FFF4EA] p-8 flex flex-col">
      <div className="flex-1 text-center">
        <img className="w-full border-2" src={state.data} alt="result_img" />
        {
          isDownloaded
            ? <>
              <div className="beatrice-font font-bold text-xl mt-8 mb-2">Your image is dowloaded.</div>
              <div className="domaine-regular text-xl">You can find this image in your phone’s photo library.</div>
            </>
            : <>
              <div className="beatrice-font font-bold text-xl mt-8 mb-2">To talk to a doctor we’ll need you to download your results image. Please share it with your doctor.</div>
              <div className="domaine-regular text-xl">You may also be asked to upload this photo during a chat experience.</div>
            </>
        }
      </div>
      <PurpleBtn func={download}>

        {
          isDownloaded
            ? "Continue to Treatment"
            : "Download results image"
        }
      </PurpleBtn>
      <TransparentBtn className="mt-2" func={goBack}>Back</TransparentBtn>
    </div>
  )
}

export default DownloadPage;