import { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NoSleep  from 'nosleep.js';
import StixLogoImg from "./assets/img/stix-logo.png";
import WinxLogoImg from "./assets/img/winx-logo.png";

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import MyRoutes from './routes';

function App() {
  const [disabledBack, setDisabledBack] = useState(false);
  const [clickBack, setClickBack] = useState(0);
  const [isDownload, setIsDownload] = useState(0);
  const isStix = process.env.REACT_APP_WINX_OR_STIX === "WINX" ? false : true;
  const noSleep = new NoSleep();

  useEffect(() => {
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);
  }, []);

  return (
    <div className="stretch-height flex justify-center">
      <div className='flex flex-col w-[800px]'>
        {
          !isDownload &&
            <div className={`w-full flex justify-between items-center ${isStix && "px-4 py-2"} bg-[#f3f3f3]`}>
              <img className={isStix ? "w-16" : "w-48"} src={isStix ? StixLogoImg : WinxLogoImg} alt='logo' />
              {/* <div className='w-10 h-10 rounded-full bg-[#6e66bc] flex items-center justify-center'><FontAwesomeIcon color='white' icon={faBars} /></div> */}
            </div>
        }
        <BrowserRouter>
          <MyRoutes setDisabledBack={setDisabledBack} clickBack={clickBack} checkUrl={setIsDownload} isStix={isStix} />
        </BrowserRouter>
      </div>
      {/* <button className='fixed top-20 right-6 w-8 h-8 rounded-full bg-[#6e66bc] flex items-center justify-center' disabled={disabledBack} onClick={() => setClickBack(old => old + 1)}>
        <FontAwesomeIcon color='white' icon={faArrowLeft} />
      </button> */}
      <ToastContainer />
    </div>
  );
}

export default App;
