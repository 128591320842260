import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faVolumeHigh, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import PurpleBtn from '../Components/PurpleBtn';
import TransparentBtn from '../Components/TransParentBtn';
import { UTI_STIX_STEPS, UTI_WINX_STEPS, SERVER_URL } from '../const/const';
import posthog from 'posthog-js';

import UTIWinxTestImg from "../assets/img/stix-uti-test.png";
import UTIStixTestImg from "../assets/img/uti-test-stix.png";
import SpinnerImg from "../assets/img/spinner.gif";
import AlertMP3 from "../assets/audio/alert.mp3";

const audioElement = new Audio(AlertMP3);
audioElement.loop = true;

const TIMER_LENGTH = process.env.REACT_APP_PERIOD;

const UTIStepsPage = (props) => {
  const isStix = props.isStix;
  const UTITestImg = isStix ? UTIStixTestImg : UTIWinxTestImg;
  const UTI_STEPS = isStix ? UTI_STIX_STEPS : UTI_WINX_STEPS;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
	const [currentStep, setCurrentStep] = useState(0);
	const [timer, setTimer] = useState(TIMER_LENGTH);
	const [timerId, setTimerId] = useState(null);
  const [min, setMin] = useState("0");
  const [sec, setSec] = useState("00");
  const [onAudio, setOnAudio] = useState(true);
  const [isAlarmStart, setIsAlarmStart] = useState(false);
  const [isButtonDisaabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState(null);


  const handleFileChange = async (event) => {
    console.log("Inside input")

    localStorage.setItem("result", null);
    const formData = new FormData();
    setImg(URL.createObjectURL(event.target.files[0]));
    formData.append("image", event.target.files[0])
    // event.target.value = ''
    try {
      setIsLoading(true);
      const res = await axios.post(SERVER_URL, formData);
      await new Promise(r => setTimeout(r, 2000));
      localStorage.setItem("result", res.data.strip_image);
      localStorage.setItem("teleImg", res.data.teledoc_image);

      if (parseInt(res.data.LEU) >= 2) {
        res.data.LEU = "POS"
      } else {
        res.data.LEU = "NEG"
      }
      if (parseInt(res.data.NIT) >= 1) {
        res.data.NIT = "POS"
      } else {
        res.data.NIT = "NEG"
      }

      localStorage.setItem("leu", res.data.LEU);
      localStorage.setItem("nit", res.data.NIT);
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('scan_succeeded')
      }
      window.heap.track('scan_succeeded');
    } catch (error) {
      localStorage.setItem("result", null);
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('scan_failed')
      }
    }
    setIsLoading(false);
    navigate("/result")
    // fileInput.remove();

  }

  const onNextBtn = () => {

    // Navigate to the attentive page
    if (currentStep === 0) {
      navigate(`/info`);
      return;
    }

    if (currentStep < UTI_STEPS.length) {
      navigate(`/?step=${currentStep + 1}`);
      if (currentStep === 2 && timerId === null && timer > 0) {
        audioElement.play();
        audioElement.pause();
        const current = Math.floor(Date.now() / 1000);
        localStorage.setItem("target", current + parseInt(TIMER_LENGTH));
        const newTimerId = setInterval(onTimer, 1000);
        localStorage.setItem("timerId", newTimerId);
        setTimerId(newTimerId);
      }
    } else {
      localStorage.setItem("timerId", null);
      console.log(isAlarmStart)
      isAlarmStart && audioElement.pause();
      document.getElementById("photoTaker").click()
		}
  }

  const onPrevBtn = () => {
    if (currentStep > 1) {
      navigate(`/?step=${currentStep - 1}`);
    }
    if (currentStep === 1) {
      navigate(`/`);
    }
  }

  const onTimer = () => {
    const current = Math.floor(Date.now() / 1000);
    const target = parseInt(localStorage.getItem("target"));
    setTimer(target - current);
  }

  const switchAudio = (flag) => {
    setOnAudio(flag);
    audioElement.pause();
  }

  const playAlarm = () => {
    setIsAlarmStart(true);
    audioElement.play();
    setTimeout(() => {
      audioElement.pause();
      setIsAlarmStart(false);
    }, 6000);
  };

  useEffect(() => {
    if (currentStep === UTI_STEPS.length && timer > 0) {
      setIsButtonDisabled(true)
    }
  })

  useEffect(() => {
    if (timerId) {
      if (timer <= 0) {
        clearInterval(timerId);
        if (onAudio) {
          playAlarm()
        } 
        // setTimerId(null)
        setSec("00");
        localStorage.setItem("timerId", null);
        localStorage.setItem("timer", 0);
        setIsButtonDisabled(false);
      } else {
        localStorage.setItem("timer", timer);
        const newMin = Math.floor(timer / 60);
        const newSec = timer % 60;
        setMin(newMin.toString());
        setSec(newSec < 10 ? `0${newSec}` : newSec.toString())
        if (currentStep === UTI_STEPS.length) {
          setIsButtonDisabled(true);
        }
      }
    } else {
      setTimer(TIMER_LENGTH);
    }
  }, [timer, timerId])

  useEffect(() => {
    if (searchParams.get("step")) {
      setCurrentStep(parseInt(searchParams.get("step")));
    }
  }, [searchParams])

  useEffect(() => {
    if (localStorage.getItem("timerId") !== "null" && localStorage.getItem("timerId") !== null) {
      clearInterval(parseInt(localStorage.getItem("timerId")));
      setTimer(localStorage.getItem("timer") * 1);
      const newTimerId = setInterval(onTimer, 1000);
      localStorage.setItem("timerId", newTimerId)
      setTimerId(newTimerId);
    }
  }, [])


	return (
		<>
      {
        !isLoading 
          ? <>
              <div className='flex relative w-full flex-col items-center justify-center bg-[#e8e4f2] text-4xl font-bold'>
              <input id="photoTaker" type="file" accept="image/*" className="hidden" capture onChange={handleFileChange}></input>
              {
                !!timerId && 
                  <div id="timer" className={`z-50 absolute top-0 right-0 py-1 timer px-4 rounded-full ${timer ? "bg-black" : "bg-red-600"} text-white beatrice-font font-bold xxxs:text-sm xxs:text-lg xs:text-2xl flex justify-center leading-none right-4`}>
                    <span>{min}:{sec}</span>
                    {
                      onAudio
                        ? <button className='mt-[1px] ml-2' onClick={() => switchAudio(false)}><FontAwesomeIcon icon={faVolumeHigh} /></button>
                        : <button className='mt-[1px] ml-2' onClick={() => switchAudio(true)}><FontAwesomeIcon icon={faVolumeMute} /></button>
                    }
                  </div>
              } 
              {
                currentStep === 0
                  ? <>
                      <div className='text-[#1a618d] beatrice-font' >{isStix ? "Stix" : "Winx"} UTI</div>
                      <div className='domaine-bold xxxs:mb-16 xxs:mb-0'>Test & Treat</div>
                      <img className='absolute h-full hidden' src={UTI_STEPS[0].img} alt={UTI_STEPS[0].img_alt} />
                    </> 
                  : UTI_STEPS.map((step, index) => 
                    <img key={index} className={`flex max-h-80 ${index === currentStep - 1 ? 'block' : 'hidden'}`} src={step.img} alt={step.img_alt} />
                  ) 
              }
              {
                currentStep === 0 &&
                <div className="absolute top-48 mr-10  w-32 xxxs:w-40 xxs:w-48 xs:w-56">
                  <img className="" src={UTITestImg} alt='stix-uti-test' />
                </div>
              }
            </div>
            <div className='bg-[#fff4ea] flex-1 flex flex-col pb-6 px-4'>
              {
                currentStep > 0 &&
                  <div className='flex flex-col items-center xxs:mb-8 xs:mb-16'>
                    <div className='flex my-4'>
                      {
                        Array.from({length: UTI_STEPS.length}).map((_, index) =>
                          <div key={index} className={`w-2 h-2 rounded-full mx-2 ${index + 1 <= currentStep ? "bg-[#6e66bc]" : "bg-[#d9d9d9]"}`}></div>
                        )
                      }
                    </div>
                    <span className='domaine-regular xxxs:text-sm xxs:text-lg xs:text-2xl xxxs:mb-2 xxs:mb-4 xs:mb-6'>Step {currentStep}</span>
                    <div className='w-full beatrice-font xxxs:text-sm xxs:text-lg xxs:leading-tight xs:text-2xl font-bold text-left xxs:mb-2 xs:mb-4'>{UTI_STEPS[currentStep - 1].title}</div>
                    <div className='w-full domaine-regular xxxs:text-sm xxs:text-lg xxs:leading-tight xs:text-2xl text-left'>{UTI_STEPS[currentStep - 1].description}</div>
                  </div>
              }
              <div className='flex-1'></div>
              <div className=''>
                  <PurpleBtn disabled={isButtonDisaabled} func={onNextBtn} >
                    {
                      currentStep === 0
                        ? "Get Started"
                        : UTI_STEPS[currentStep - 1].next_btn
                    }
                  </PurpleBtn>
                  {
                    currentStep > 0 && currentStep < UTI_STEPS.length &&
                      <TransparentBtn className="mt-2" func={onPrevBtn} > 
                        <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
                        Go Back
                      </TransparentBtn>
                  }
              </div>
            </div>
          </>
          : <div className='w-full h-full bg-[#6E66BC] flex flex-col items-center justify-between beatrice-font text-white'>
            <img className='w-40 xxs:w-52 xs:w-60 mt-16' src={img} alt='img' />
            <div className='flex flex-col items-center'>
              <img className='w-40' src={SpinnerImg} alt='spinner' />
              <div className='beatrice-font text-xl'>AI Analysis Engaged</div>
              <div className='domaine-regular text-lg'>Sit back and relax</div>
            </div>
            <div className='flex my-8'>
            </div>
          </div>
      }
      
		</>
	)
}

export default UTIStepsPage;