import { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import StartPage from './pages/StartPage';
import UTIStepsPage from './pages/UTIStepsPage';
import AttentivePage from './pages/AttentivePage';
import ResultPage from './pages/ResultPage';
import CameraPage from './pages/CameraPage';
import DownloadPage from './pages/DownloadPage';
import posthog from 'posthog-js';


const MyRoutes = ({setDisabledBack, clickBack, checkUrl, isStix}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const isFirstPage = location.key === '1'; // Check if it's the first page of the history
        setDisabledBack(isFirstPage)
        const check = location.pathname.indexOf("download") > -1 ? true : false;
        checkUrl(check)
    }, [location]);

    useEffect(() => {
        if (clickBack > 0) {
            navigate(-1);
        }
    }, [clickBack])
    
    useEffect(() => {
      if (['prod'].includes(process.env.REACT_APP_ENV)) {
        posthog.capture('$pageview')
        console.log("PostHog: pageview")
      }
    }, [location]);

    return (
        <Routes>
            {/*<Route path="/" element={<StartPage isStix={isStix} />} />*/}
            {/*<Route path="/steps" element={<UTIStepsPage isStix={isStix} />} />*/}
            <Route
              path="/"
              element={
                parseInt(new URLSearchParams(window.location.search).get('step'), 10) >= 1 ? (
                  <UTIStepsPage isStix={isStix} />
                ) : (
                  <StartPage isStix={isStix} />
                )
              }
            />

            {
                process.env.REACT_APP_ENV === "stg" || process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "dev"
                ? <Route path="/info" element={<Navigate to="/?step=1" replace />} />
                : <Route path="/info" element={<AttentivePage />} />
            }
            <Route path="/attentive-end" element={<Navigate to="/?step=1" replace />} />
            <Route path='/camera' element={<CameraPage />} />
            <Route path='/result' element={<ResultPage isStix={isStix} />} />
            <Route path='/download' element={<DownloadPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}

export default MyRoutes