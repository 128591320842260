import Step1Img from "../assets/img/steps/Image-1.jpg"
import Step2Img from "../assets/img/steps/Image-2.jpg"
import Step3Img from "../assets/img/steps/Image-3.jpg"
import Step4Img from "../assets/img/steps/Image-4.jpg"
import Step5Img from "../assets/img/steps/Image-5.jpg"

import WinxStep1Img from "../assets/img/winx-steps/strip-holding.jpg"
import WinxStep2Img from "../assets/img/winx-steps/dripping-urine.jpg"
import WinxStep3Img from "../assets/img/winx-steps/dabbing.jpg"
import WinxStep4Img from "../assets/img/winx-steps/on-flat.jpg"
import WinxStep5Img from "../assets/img/winx-steps/picture-snap.jpg"


const UTI_WINX_STEPS = [
	{
		id: "open_pouch",
		img: WinxStep1Img,
		img_alt: "IMG opening pouch",
		title: "Open the foil pouch and grab the test by the plastic thumb grip.",
		next_btn: "Next",
		description: "If the test has been left out of the pouch for 20 minutes or more, discard the UTI Test Strip and start again with a new Winx Health Test Strip."
	},
	{
		id: "urine_on_strip",
		img: WinxStep2Img,
		img_alt: "IMG strip in stream",
		title: "Pee for a few seconds, then hold the tip of the test strip in your urine stream for 1-2 seconds.",
		next_btn: "Both Pads are Wet - Start Timer",
		description: "Both of the test pad squares should be damp. If it’s easier, dip the test pads in a small cup of urine for 1-2 seconds instead."
	},
	{
		id: "paper_dab",
		img: WinxStep3Img,
		img_alt: "IMG dabbing strip on paper towel",
		title: "Gently dab the test strip on a paper towel to remove excess urine.",
		next_btn: "Next",
		description: "Dab several times, if necessary."
	},
	{
		id: "strip_on_card",
		img: WinxStep4Img,
		img_alt: "IMG placing strip on color card",
		title: "Lay the UTI Test Strip with the test pads facing up onto the color card panel.",
		next_btn: "Next",
		description: "Be sure the test pad is centered on the card as shown."
	},
	{
		id: "take_photo",
		img: WinxStep5Img,
		img_alt: "IMG taking picture of card and strip",
		title: "When the timer finishes counting down, take a picture of the test strip and color card. ",
		next_btn: "Take My Picture",
		description: "You’ll capture the image on the next screen. Be sure the picture is clear, and in focus."
	},
];
const UTI_STIX_STEPS = [
	{
		id: "open_pouch",
		img: Step1Img,
		img_alt: "IMG opening pouch",
		title: "Open the foil pouch and grab the test by the plastic thumb grip.",
		next_btn: "Next",
		description: "If the test has been left out of the pouch for 20 minutes or more, discard the UTI Test Strip and start again with a new Stix Test Strip."
	},
	{
		id: "urine_on_strip",
		img: Step2Img,
		img_alt: "IMG strip in stream",
		title: "Pee for a few seconds, then hold the tip of the test strip in your urine stream for 1-2 seconds.",
		next_btn: "Both Pads are Wet - Start Timer",
		description: "Both of the test pad squares should be damp. If it’s easier, dip the test pads in a small cup of urine for 1-2 seconds instead."
	},
	{
		id: "paper_dab",
		img: Step3Img,
		img_alt: "IMG dabbing strip on paper towel",
		title: "Gently dab the test strip on a paper towel to remove excess urine.",
		next_btn: "Next",
		description: "Dab several times, if necessary."
	},
	{
		id: "strip_on_card",
		img: Step4Img,
		img_alt: "IMG placing strip on color card",
		title: "Lay the UTI Test Strip with the test pads facing up onto the color card panel.",
		next_btn: "Next",
		description: "Be sure the test pad is centered on the card as shown."
	},
	{
		id: "take_photo",
		img: Step5Img,
		img_alt: "IMG taking picture of card and strip",
		title: "When the timer finishes counting down, take a picture of the test strip and color card. ",
		next_btn: "Take My Picture",
		description: "You’ll capture the image on the next screen. Be sure the picture is clear, and in focus."
	},
];


const SERVER_URL = process.env.REACT_APP_API;

export {
	UTI_STIX_STEPS,
	UTI_WINX_STEPS,
	SERVER_URL
}