import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PurpleBtn from '../Components/PurpleBtn';
import UTIWinxTestImg from "../assets/img/winx-uti-test.png";
import UTIStixTestImg from "../assets/img/uti-test-stix.png";



const StartPage = (props) => {
    const isStix = props.isStix;
    const navigate = useNavigate();
    const UTITestImg = isStix ? UTIStixTestImg : UTIWinxTestImg;
    const title = isStix ? "Stix UTI" : "Winx Health UTI";
    const subTitle = isStix ? "Test & Treat" : "Test + Treat";
    const background = isStix? "bg-[#e8e4f2]" : "bg-[#0083C7]";
    const titleColor = isStix? "text-[#1a618d]" : "text-[#ffffff]";
    const subTitleColor = isStix? "" : "text-[#ffffff]";
    const imageCss = isStix? "w-32 xxxs:w-40 xxs:w-48 xs:w-56 mr-10" : "w-32 xxxs:w-32 xxs:w-40 xs:w-48 mt-5";

    const onNextBtn = () => {
        navigate(`/info`);
        return;
    }

    return (
        <>
            <div class={`flex w-full h-1/3 flex-col items-center justify-center ${background} text-4xl font-bold`}>
                <div class={`relative top-40  beatrice-font ${titleColor}`}>
                    {title}
                </div>
                <div class={`relative top-40 domaine-bold xxxs:mb-16 xxs:mb-0 ${subTitleColor}`}>
                    {subTitle}
                </div>

                <div className={`relative top-40  ${imageCss}`}>
                  <img className="" src={UTITestImg} alt='stix-uti-test' />
                </div>
            </div>
            <div class="flex-1 flex flex-col items-center justify-end bg-[#FFF4EA] text-4xl pb-6 px-4 font-bold">
                <div class="flex-grow"></div>
                <PurpleBtn disabled={false} func={onNextBtn}>
                    Get Started
                </PurpleBtn>
            </div>
        </>
    )
}

export default StartPage;
